import {action, configure, makeObservable, observable} from 'mobx';
import isEmpty from "is-empty";
import {Inertia} from "@inertiajs/inertia";

/**
 * Класс search
 */
export default class SearchStore {
    /**
     * Тип поиска (start, search, suggestions)
     * @type {string}
     */
    @observable searchType = 'start';
    /**
     * Тип поисковых данных
     * @type {string}
     */
    @observable searchValueType = 'search';
    /**
     * Поисковой запрос
     * @type {string}
     */
    @observable searchQuery = '';
    /**
     * Сохраненный поисковой запрос
     * @type {string}
     */
    searchQueryOld = '';
    /**
     * Поисковой запрос, для фронта (вывод для пользователя)
     * @type {string}
     */
    @observable valueOutput = '';
    /**
     * Результат поискового запроса
     * @type {[]}
     */
    @observable searchResultProductAll = [];
    /**
     * Результат поискового запроса, количество
     * @type {number}
     */
    @observable searchResultCountAll = 0;
    /**
     * Продукты по дефолту при переходе на страницу поиска
     * @type {[]}
     */
    @observable searchResultProductDefault = [];
    /**
     * Продукты, если ничего не найдено
     * @type {[]}
     */
    @observable searchResultProductPopular = [];
    /**
     * Список поисковых предложений
     * @type {[]}
     */
    @observable searchSuggestions = [];
    /**
     * История поисковых запросов
     * @type {[]}
     */
    @observable searchHistoryData = [];
    // Пагинация
    /**
     * Активная страница
     * @type {number}
     */
    @observable searchActivePage = 1;
    /**
     * Сколько показывать на странице
     * @type {number}
     */
    @observable searchListCount = 40;
    /**
     * Пропуск заданного количества результатов
     * @type {number}
     */
    @observable searchOffset = 0;
    /**
     * Ограничение количества результатов
     * @type {number}
     */
    @observable searchLimit = 40;
    /**
     * Текст сообщения, если ничего не найдено
     * @type {string}
     */
    @observable emptyResultMessage = '';
    /**
     * Данные для построения фасетных фильтров
     * @type {{insertsColor: [], weaving: [], viewDesign: [], thematics: [], price: [{minimumPrice: number},{maximumPrice: number}], sex: [], collection: [], inserts: [], categoryAndView: [], metalAndType: []}}
     */
    @observable facetFilterData = {
        categoryAndView: [],
        inserts: [],
        insertsColor: [],
        metalAndType: [],
        sex: [],
        size: [],
        viewDesign: [],
        thematics: [],
        collection: [],
        weaving: [],
        price: {'minimumPrice': 0, 'maximumPrice': 0},
    };
    /**
     * Набранные фильтры
     * @type {{insertsColor: [], view: [], weaving: [], viewDesign: [], thematics: [], price: [], sex: [], metal: [], collection: [], category: [], inserts: [], metalType: []}}
     */
    @observable filterParams = {
        category: [],
        view: [],
        inserts: [],
        insertsColor: [],
        metal: [],
        metalType: [],
        sex: [],
        size: [],
        viewDesign: [],
        thematics: [],
        collection: [],
        weaving: [],
        price: [], // пример - [{'minimumPrice': 5000, 'maximumPrice': 10000}]
    };
    /**
     * Выбранная цена
     * @type {[]}
     */
    @observable priceChoice = [];
    /**
     * Диапазон ползунка цены
     * @type {number[]}
     */
    @observable priceSliderValue = [0, 100];
    /**
     * Минимальная цена
     * @type {number}
     */
    @observable minimumPrice = 0;
    minimumStartPrice = 0;
    /**
     * Максимальная цена
     * @type {number}
     */
    @observable maximumPrice = 0;
    maximumStartPrice = 0;
    /**
     * Текущая сортировка
     * @type {string[]}
     */
    @observable sort = ['hit', 'desc'];
    /**
     * Данные для отображения сортировки
     * @type {[]}
     */
    @observable dataSort = [];
    /**
     * Название выбранной сортировки
     * @type {string}
     */
    @observable selectedSortingName = 'По популярности';

    @observable categoryAndViewFilterFlag = false;
    @observable insertsFilterFlag = false;
    @observable insertsColorFilterFlag = false;
    @observable metalAndTypeFilterFlag = false;
    @observable sexFilterFlag = false;
    @observable sizeFilterFlag = false;
    @observable viewDesignFilterFlag = false;
    @observable thematicsFilterFlag = false;
    @observable collectionFilterFlag = false;
    @observable weavingFilterFlag = false;
    @observable priceFilterFlag = false;
    @observable sortFilterFlag = false;
    @observable choiceFiltersFlag = false;

    /**
     * Начальное состояние модального окна поиска
     * @type {boolean}
     */
    @observable open = false;
    /**
     * Фокус
     * @type {boolean}
     */
    @observable onfocus = true;
    /**
     * Всплывашка для поиска с историей ввода или подсказками
     * @type {boolean}
     */
    @observable onHint = false;
    /**
     * Лоадер для поиска, пока не пришел ответ с сервера
     * @type {boolean}
     */
    @observable searchLoader = false;

    flagStart = false;
    @observable newImg = '';
    @observable dataSearchStart = [];

    constructor(rootStore) {
        makeObservable(this)
        configure({
            enforceActions: "never",
        })
        this.rootStore = rootStore;
        this.searchHistoryData = localStorage.getItem('searchHistoryData') ? localStorage.getItem('searchHistoryData').split('|') : [];
    }

    /**
     * Событие фокус у поля поиска
     * @param event
     */
    @action onSearchFocus = () => {
        this.onfocus = false;
        this.onHint = true;
    }

    /**
     * Потеря фокуса у поля поиска
     * @param event
     */
    @action onSearchBlur = () => {
        setTimeout(() => {
            this.onHint = false;
            this.onfocus = true;
        }, 500);
    }

    /**
     * Открытие модального окна поиска
     */
    @action openSearchModal = () => {
        this.open = true;
        this.searchType = 'start';
        this.apiAllSearch();
    };

    /**
     * Закрытие модального окна поиска
     */
    @action closeSearchModal = () => {
        this.open = false;
        this.flagStart = false;
    };

    /**
     * Обработка нажатия клавиш
     * @param event
     */
    @action onSearchKeyDown = (event) => {
        if (event.key === 'Enter') {
            this.stopEvents(event);
            if (this.searchQuery !== '') {
                this.valueOutput = this.searchQuery;
                this.searchHistoryUpdate();

                this.searchType = 'search';
                this.apiAllSearch();

                event.target.blur();
            }
        }
    }

    /**
     * Обработка нажатия кнопки поиска
     */
    @action onClickSearchButton = () => {
        if (this.searchQuery !== '') {
            this.valueOutput = this.searchQuery;
            this.searchHistoryUpdate();
            this.onHint = false;

            this.searchType = 'search';
            this.apiAllSearch();
        }
    }

    /**
     * Обработка истории запросов
     * @param el
     */
    @action onChangeHistory = (el) => {
        if (this.searchQuery !== el && this.searchActivePage !== 1) {
            this.resettingParametersShowBtn();
        }

        this.searchQuery = el;
        this.valueOutput = el;
        this.onHint = false;
        if (this.searchQuery !== '') {

            this.searchType = 'search';
            this.apiAllSearch();
        }
    }

    /**
     * Удалить строку запроса
     */
    @action deleteSearchValue = (searchInput) => {
        if (this.searchQuery !== '') {
            this.searchQuery = '';
        }

        setTimeout(() => {
            this.onHint = true;
            if (searchInput !== null) {
                searchInput.focus();
            }
        }, 0);
    }

    /**
     * Удаляем всю историю поисковых запросов
     */
    @action deleteHistoryAll = () => {
        this.searchHistoryData = [];
        localStorage.setItem('searchHistoryData', '');
    }

    /**
     * Обработка истории запросов
     * @param element
     */
    @action searchHistoryUpdate = () => {
        let searchHistoryData = [], searchHistoryDataNew = [];
        searchHistoryData = localStorage.getItem('searchHistoryData') ? localStorage.getItem('searchHistoryData').split('|') : [];
        searchHistoryDataNew = searchHistoryData.filter((el) => !isEmpty(el) && String(el) !== String(this.searchQuery));

        if (searchHistoryDataNew.length >= 5) {
            searchHistoryDataNew = searchHistoryDataNew.slice(0, 4);
        }

        searchHistoryDataNew.unshift(String(this.searchQuery));
        this.searchHistoryData = searchHistoryDataNew;
        localStorage.setItem('searchHistoryData', searchHistoryDataNew.join('|'));
    };

    /**
     * Обработка пагинации
     * @param event
     */
    @action changeSearchPagination = (pageNumber) => {
        try {
            if (Number(this.searchActivePage !== pageNumber)) {
                this.searchType = 'search';
                this.searchActivePage = pageNumber;
                this.searchOffset = this.searchActivePage * this.searchLimit - this.searchLimit;
                this.apiAllSearch();
            }
        } catch (e) {
            console.log('e', e);
        }
    };

    /**
     * Сброс пагинации
     */
    @action resettingParametersShowBtn = () => {
        this.searchActivePage = 1;
        this.searchOffset = 0;
    };

    /**
     * Запускаем поиск по продуктам
     * @param e
     */
    @action searchChange = (e) => {
        this.flagStart = true;
        if (e && this.searchQuery !== e.target.value && this.searchActivePage !== 1) {
            this.resettingParametersShowBtn();
        }

        if (e) {
            this.searchQuery = e.target.value;
        }
        if (this.searchValueType !== 'search') {
            this.searchValueType = 'search';
        }

        if (this.searchQuery !== '') {
            this.searchType = 'suggestions';

            if (!this.onHint) {
                this.onHint = true;
            }
        }

        if (!e || this.searchQuery !== '') {
            // Запускаем поиск с задержкой в полсекунды
            let date = new Date();
            window.flagTimeSuggestions = date.setMilliseconds(date.getMilliseconds() + 500);

            setTimeout(() => {
                if (window.flagTimeSuggestions <= new Date()) {
                    this.apiAllSearch();
                }
            }, 500);
        }
    };

    /**
     * Запрос на поиск
     */
    @action apiAllSearch = async () => {
        try {
            if (this.emptyResultMessage !== '') {
                this.emptyResultMessage = '';
            }

            if (this.searchType === 'search') {
                this.searchLoader = true;

                if (this.searchQueryOld !== '' && this.searchQuery !== this.searchQueryOld) {
                    this.resettingFilters(); // Сбрасываем фильтры
                    this.resettingPagination(); // Сбрасываем пагинацию
                    this.searchOffset = 0;
                }
            }

            const response = await axios.post('/api/search', {
                queryString: this.searchQuery,
                searchType: this.searchType,
                searchActivePage: this.searchActivePage,
                searchOffset: this.searchOffset,
                searchLimit: this.searchLimit,
                filterParams: this.filterParams,
                sort: this.sort,
                redirect: true,
            });

            if (this.searchType === 'search') {
                this.searchQueryOld = this.searchQuery;
            }

            const {success} = response.data;

            this.searchLoader = false;
            if (!response) {
                return false;
            }

            if (success.status) {
                const {redirectLink} = success.data;
                if (redirectLink) {
                    this.searchQuery = '';
                    return Inertia.visit(redirectLink);
                }

                const {
                    searchResultProductAll,
                    searchResultCountAll,
                    searchResultProductDefault,
                    searchResultProductPopular,
                    searchSuggestions,
                    facetFilterData,
                    dataSort
                } = success.data;

                // Старт поиска
                if (this.searchType === 'start') {
                    this.searchResultProductDefault = searchResultProductDefault;
                }
                // Обновляем подсказки
                if (this.searchType === 'suggestions' || this.searchType === 'search') {
                    this.searchSuggestions = searchSuggestions;
                }
                // Обновляем результаты поиска
                if (this.searchType === 'search') {
                    this.searchResultProductDefault = searchResultProductDefault;
                    this.searchResultProductAll = searchResultProductAll;
                    this.searchResultCountAll = searchResultCountAll;
                    this.searchResultProductPopular = searchResultProductPopular;
                    this.facetFilterData = facetFilterData;
                    this.dataSort = dataSort;
                    if (this.priceChoice.length === 0) {
                        this.minimumPrice = facetFilterData.price.minimumPrice;
                        this.maximumPrice = facetFilterData.price.maximumPrice;
                    }
                    this.minimumStartPrice = facetFilterData.price.minimumPrice;
                    this.maximumStartPrice = facetFilterData.price.maximumPrice;

                    if (searchResultCountAll === 0) {
                        this.emptyResultMessage = 'По Вашему запросу ничего не найдено';
                    } else {
                        this.emptyResultMessage = '';
                    }
                }

                window.scrollTo(0, 0);
            }
        } catch (e) {
            if (this.searchType === 'search') {
                this.emptyResultMessage = '';
            }
            console.log('e', e);
        }
    };

    /**
     * Сбрасываем все фильтры (кнопка на сайте)
     */
    @action resettingAllFilters = () => {
        try {
            this.resettingFilters();
            this.apiAllSearch();
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Сбрасываем все фильтры
     */
    @action resettingFilters = () => {
        try {
            this.filterParams = {
                category: [],
                view: [],
                inserts: [],
                insertsColor: [],
                metal: [],
                metalType: [],
                sex: [],
                size: [],
                viewDesign: [],
                thematics: [],
                collection: [],
                weaving: [],
                // price: [{'minimumPrice': this.minimumStartPrice, 'maximumPrice': this.maximumStartPrice}],
                price: [],
            }
            this.resettingPrice();
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Сбрасываем конкретный фильтр
     * @param el
     */
    @action resettingItem = (el) => {
        try {
            if (el.name === 'priceChoice') {
                this.resettingPrice();
                this.filterParams.price = [];
            } else {
                this.filterParams[el.name] = this.filterParams[el.name].filter((item) => el.value !== item.value);
                if (el.name === 'category') {
                    this.filterParams.view = [];
                }
                if (el.name === 'metal') {
                    this.filterParams.metalType = [];
                }
            }
            this.apiAllSearch();
        } catch (e) {
            console.log('e', e);
        }
    }

    @action resettingPrice = () => {
        try {
            this.minimumPrice = this.minimumStartPrice;
            this.maximumPrice = this.maximumStartPrice;
            this.priceSliderValue = [0, 100];
            this.priceChoice = [];
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Сбрасываем пагинацию
     */
    @action resettingPagination = () => {
        try {
            this.searchActivePage = 1;
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Сбрасываем смещение
     */
    @action resettingOffset = () => {
        try {
            this.searchOffset = 0;
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Останавливаем события
     * @param e
     */
    @action stopEvents = (e) => {
        try {
            e.preventDefault();
            e.stopPropagation();
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Открыть/Закрыть фильтры
     * @param filterName
     */
    @action toggleFilter = (filterName) => {
        try {
            this[filterName] = !this[filterName];
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Закрыть фильтры
     * @param e
     * @param filterName
     */
    @action closeFilter = (e, filterName) => {
        try {
            if (this[filterName]) {
                this[filterName] = false;
            }
        } catch (e) {
            console.log('e', e);
        }
    }

    @action changeSorting = (el) => {
        try {
            this.selectedSortingName = el.name;
            this.sort = [el.sortField, el.sortType];
            // this.sortFilterFlag = false;
            this.apiAllSearch(); // Запрашиваем данные
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Изменение категории
     * @param e
     * @param filterName имя категории, например "category"
     * @param filterChildName имя под категории, например "view"
     * @param parentName ранее выбранная родительская категория, например "Кольца"
     */
    @action changeCategory = (e, filterName, filterChildName, parentName) => {
        try {
            // Работаем родительским элементом (категорией)
            if (this.filterParams[filterName].length !== 0) {
                this.filterParams[filterName] = [];
            }
            const el = {
                'name': filterName,
                'value': e.target.value,
            }
            this.filterParams[filterName].push(el);

            // Работаем с вложенным элементом (под категорией)
            if (parentName !== null && e.target.value !== parentName) {
                this.filterParams[filterChildName] = [];
            }

            this.resettingPagination(); // Сбрасываем пагинацию
            this.resettingOffset(); // Сбрасываем смещение
            this.apiAllSearch(); // Запрашиваем данные
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Изменение под категории
     * @param e
     * @param filterName имя под категории, например "view"
     * @param filterParentName имя категории, например "category"
     * @param filterParentValue значение категории, например "Кольца"
     */
    @action changeSubCategory = (e, filterName, filterParentName, filterParentValue) => {
        try {
            // Работаем с вложенным элементом (под категорией)
            if (this.filterParams[filterName].length !== 0) {
                this.filterParams[filterName] = [];
            }
            const el = {
                'name': filterName,
                'value': e.target.value,
            }
            this.filterParams[filterName].push(el);

            // Работаем родительским элементом (категорией)
            if (this.filterParams[filterParentName].length !== 0) {
                this.filterParams[filterParentName] = [];
            }
            const elParent = {
                'name': filterParentName,
                'value': filterParentValue,
            }
            this.filterParams[filterParentName].push(elParent);

            this.resettingPagination(); // Сбрасываем пагинацию
            this.resettingOffset(); // Сбрасываем смещение
            this.apiAllSearch(); // Запрашиваем данные
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Изменение чек боксов
     * @param e
     * @param filterName имя категории, например "inserts"
     */
    @action changeCheckBox = (e, el, filterName) => {
        try {
            const data = {
                'name': filterName,
                'value': el.key,
            }
            if (e.target.checked) {
                this.filterParams[filterName].push(data);
            } else {
                this.filterParams[filterName] = this.filterParams[filterName].filter((item) => item.value !== el.key);
            }

            this.resettingPagination(); // Сбрасываем пагинацию
            this.resettingOffset(); // Сбрасываем смещение
            this.apiAllSearch(); // Запрашиваем данные
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Изменение цены (от и до)
     * @param e
     * @param filterName имя категории, например "price"
     * @param rangeType тип диапазона, например "minimumPrice"
     */
    @action changePriceInput = (e, filterName, rangeType) => {
        try {
            this[rangeType] = Math.round(isEmpty(e.target.value) ? 0 : Number(e.target.value));

            let firstValue = ((this.minimumPrice - this.minimumStartPrice) * 100 / (this.maximumStartPrice - this.minimumStartPrice));
            let secondValue = ((this.maximumPrice - this.minimumStartPrice) * 100 / (this.maximumStartPrice - this.minimumStartPrice));
            this.priceSliderValue = [firstValue, secondValue];

        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Изменение цены (ползунок слайдера)
     * @param e
     * @param value новые значения
     */
    @action changePriceSlider = (e, value) => {
        try {
            this.minimumPrice = (Number(this.minimumStartPrice) + (Number(this.maximumStartPrice) - Number(this.minimumStartPrice)) / 100 * Number(value[0])).toFixed(0);
            this.maximumPrice = (Number(this.minimumStartPrice) + (Number(this.maximumStartPrice) - Number(this.minimumStartPrice)) / 100 * Number(value[1])).toFixed(0);

            this.priceSliderValue = value;

        } catch (e) {
            console.log('e', e);
        }
    }


    @action changePrice = (filterName) => {
        try {
            if (this[filterName + 'Choice'].length !== 0) {
                this[filterName + 'Choice'] = [];
            }
            const choice = {
                'name': filterName + 'Choice',
                'value': Number(this.minimumPrice).toLocaleString('ru') + ' руб. — ' + Number(this.maximumPrice).toLocaleString('ru') + ' руб.',
            }
            this[filterName + 'Choice'].push(choice);

            if (this.filterParams[filterName].length !== 0) {
                this.filterParams[filterName] = [];
            }
            // const data = {
            //     'minimumPrice': this.facetFilterData.price.minimumPrice,
            //     'maximumPrice': this.facetFilterData.price.maximumPrice
            // }
            const data = {
                'minimumPrice': this.minimumPrice,
                'maximumPrice': this.maximumPrice
            }
            this.filterParams[filterName].push(data);

            this.resettingPagination(); // Сбрасываем пагинацию
            this.resettingOffset(); // Сбрасываем смещение
            this.apiAllSearch(); // Запрашиваем данные
        } catch (e) {
            console.log('e', e);
        }
    }
}
