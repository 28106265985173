import {action, makeObservable, observable} from 'mobx';

export default class AdminAllStore {

    @observable authConfig = {};

    @observable extension = 'ВСЕ';
    @observable sortFiles = 'time';
    @observable pathAddImage = '';
    @observable typeAdd = '';

    constructor(rootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action pathAddImageSet = (src) => {
        this.pathAddImage = src;
    };

    @action typeAddSet = (src) => {
        this.typeAdd = src;
    };

    @action clear = () => {
        this.pathAddImage = '';
        this.typeAdd = '';
    }
}
