import {observable, makeObservable, configure, action} from 'mobx';
import {toggleClass} from "@/Helpers/Function";

export default class SubMenuStore {
    // Начальное состояние submenu
    @observable open = false;
    @observable subMenuFlag = false;
    @observable catalogMobileSubMenuFlag = false;
    @observable newImg = '';
    @observable defaultImg = {'src': '/storage/img/decorations/category/all'};
    @observable categoryList = [];
    @observable categoryCurrent = [];
    @observable stickySubMenu = false;
    // Общие данные по меню
    @observable catalogMenu = [];
    // Текущая секция меню, выбранная при наведении
    @observable currentSectionsMenu = [];
    @observable checked = false;

    constructor(rootStore) {
        makeObservable(this);
        configure({
            enforceActions: "never",
        });
        this.rootStore = rootStore;

        this.newImg = this.defaultImg;
    }

    /**
     * Переключение submenu
     */
    @action openSubMenu() {
        const stickyHeader = document.getElementById('stickyHeader');
        const isSticky = stickyHeader.classList.contains('sticky');
        if (isSticky) {
            this.stickySubMenu = true;
        } else {
            this.stickySubMenu = false;
        }
        this.open = !this.open;
        if (!this.open) {
            this.newImg = this.defaultImg;
        }
    }

    /**
     * Переключение мобильного меню каталога
     */
    @action changeMobileSubMenu() {
        this.catalogMobileSubMenuFlag = !this.catalogMobileSubMenuFlag;
        // if (this.catalogMobileSubMenuFlag) {
        //     this.menuStart();
        // }
    }

    /**
     * Переключение мобильного меню каталога
     */
    // @action openMobileSubMenu() {
    //     this.catalogMobileSubMenuFlag = true;
    //     this.menuStart();
    // }

    /**
     * Переключение мобильного меню каталога
     */
    @action closeMobileSubMenu() {
        this.catalogMobileSubMenuFlag = false;
    }

    // menuStart = (catalogMenu = []) => {
    //     if (catalogMenu.length !== 0) {
    //         this.catalogMenu = catalogMenu;
    //         this.categoryCurrent = catalogMenu[0];
    //         this.subMenuAdd(catalogMenu[0].id);
    //         this.newImg = this.defaultImg;
    //     }
    // }

    /**
     * Переключение submenu каталога
     */
    @action handleClickAwaySubMenu() {
        this.open = false;
        this.stickySubMenu = false;
        this.newImg = this.defaultImg;
    }

    /**
     * Закрыть submenu SOUL
     */
    @action closeSubMenu = () => {
        this.subMenuFlag = false;
    }

    /**
     * Переключение submenu SOUL
     * @param typeSubMenu
     */
    @action subMenuOpen(typeSubMenu) {
        this.subMenuFlag = !this.subMenuFlag;
    }

    /**
     * Меняем изображения при наведении на ссылку
     * @param el
     */
    createImg(el, id) {
        let elements = document.querySelectorAll('.catalog-sub-menu__item_content');
        elements.forEach((el) => el.classList.add('hidden'));
        const currentElement = document.getElementById('catalog_sub_menu_' + id);
        currentElement.classList.remove('hidden');
        // Добавляем класс текущему элементу при наведении
        toggleClass(el, '.js-catalogMenuList');
        this.newImg = el.currentTarget.dataset;
        // this.subMenuAdd(id);
    }

    /**
     * Переключение меню на мобилке
     * @param event
     * @param el
     */
    changeCategoryMobileMenuCatalog = (event, el) => {
        let elements = document.querySelectorAll('.catalogMobileSubMenu__listContainer');
        elements.forEach((el) => el.classList.add('hidden'));
        const currentElement = document.getElementById('catalog_mobile_sub_menu_' + el.id);
        currentElement.classList.remove('hidden');
        toggleClass(event, '.js-catalogMenuList');
        // this.categoryCurrent = el;
        // this.subMenuAdd(el.id);
    }

    // @action subMenuAdd = (id) => {
    //     this.currentSectionsMenu = this.catalogMenu[id].sections;
    // }

    goBackToMenu = (typeSubMenu) => {
        if (typeSubMenu === 'soul') {
            this.subMenuFlag = false;
        }
    }

    /**
     * Переключение submenu SOUL
     * @param typeSubMenu
     */
    @action changeSubMenu = (typeSubMenu) => {
        this.subMenuFlag = true;
    }


    @action handleClickAway = () => {
        if (this.checked) {
            this.checked = false
        }
    }

    @action switchingCountries = () => {
        this.checked = !this.checked
    }
}
