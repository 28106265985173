import {observable, action, makeObservable, configure} from 'mobx';

export default class GiftCertificateStore {
    /**
     * Данные по выбранным суммам для сертификатов
     * @type {[]}
     */
    @observable nominalData = [];
    @observable nominalList = [];
    @observable newNominal = {};
    @observable template = 0;

    constructor(rootStore) {
        makeObservable(this)
        configure({
            enforceActions: "never",
        })
        this.rootStore = rootStore;
    }

    /**
     * Добавление или удаление данных по выбранным суммам для сертификатов
     * @param el
     */
    @action addOrRemoveDataNominal = (el) => {
        this.nominalData = [];
        this.nominalData.push(el);
    }

    /**
     * Очистить все данные по выбранным суммам для сертификатов
     */
    @action clearDataNominal = () => {
        this.nominalData = [];
    }

    /**
     * Добавляем свою сумму сертификата
     * @param amount
     */
    @action addNominal = (amount) => {
        this.clearDataNominal();

        const el = this.nominalList.find(item => item === amount);
        if (el === undefined) {
            // const newNominal = {
            //     'id': this.nominalList.length + 1,
            //     'amount': amount,
            //     'count': 1
            // };
            this.nominalList.push(amount);
            this.nominalList.sort((a, b) => {
                return a - b;
            });
            this.nominalData.push(amount);
        } else {
            this.nominalData.push(el);
        }
    }
}
