import React from 'react';
import './bootstrap'
import "@/Scss/all.scss";
import {createRoot} from 'react-dom/client';
import {createInertiaApp} from '@inertiajs/inertia-react';
import {InertiaProgress} from '@inertiajs/progress';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {Provider} from "mobx-react";
import {detect} from "detect-browser";
import rootStores from '@/Stores/RootStores';

InertiaProgress.init({color: '#24564A'});

window.addEventListener('scroll', function (e) {
    const bodyWrapper = document.getElementById('bodyWrapper');
    const stickyHeader = document.getElementById('stickyHeader');
    const subMenu = document.getElementById('subMenu');
    const difference = 88// разница в px между дефолтным header и header фиксированным в результате прокрутки
    const initialHeaderHeight = 112; // Начальная высота header в px
    const stickyHeaderHeight = 72; // Высота header после фиксации
    const scrollHeight = 112 // высота прокрутки в px после которой header фиксируется

    if (stickyHeader !== null) {
        // if (window.scrollY > scrollHeight && document.body.clientHeight - (difference + scrollHeight) > window.innerHeight) {
        if (window.scrollY > scrollHeight) {
            stickyHeader.classList.add('sticky');
            bodyWrapper.style.paddingTop = `${initialHeaderHeight - stickyHeaderHeight}px`;
        } else {
            stickyHeader.classList.remove('sticky');
            bodyWrapper.style.paddingTop = '0px';
        }
    }

    // if (subMenu !== null && document.body.clientHeight - (difference + scrollHeight) > window.innerHeight) {
    if (subMenu !== null) {
        if (window.scrollY > scrollHeight) {
            subMenu.classList.add('sticky');
        } else {
            subMenu.classList.remove('sticky');
        }
    }

    const backToTop = document.getElementById('backToTop');
    if (backToTop !== null) {
        if (window.scrollY > scrollHeight) {
            backToTop.classList.add('visible');
        } else {
            backToTop.classList.remove('visible');
        }
    }
});

const browser = detect();
const browserSupported = !(browser.name in window.browsers) || (window.browsers[browser.name] !== "Infinity" && parseFloat(browser.version) >= parseFloat(window.browsers[browser.name]));

createInertiaApp({
    title: (title) => `${title}`,
    resolve: (name) => {
        if (browserSupported) {
            return resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx'));
        } else {
            return resolvePageComponent(`./Pages/Browser.jsx`, import.meta.glob('./Pages/**/*.jsx'));
        }
    },
    setup({el, App, props}) {
        const root = createRoot(el);
        root.render(
            <Provider stores={rootStores}>
                <App {...props} />
            </Provider>
        );
        // Удаление элемента с классом "loaderBeck"
        const loaderBeckElement = document.querySelector('.loaderBeck');
        if (loaderBeckElement) {
            loaderBeckElement.remove();
        }
    },
}).then((e) => {
    if (!browserSupported) {
        const page = new Page();
        page.setPage('Browser');
    }
});
